import * as React from "react";
import { useRef, useEffect } from "react";
import Button from "../components/Button";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/all";
import { Link } from "../components/links";
import { RotatingText } from "react-simple-rotating-text";
import { assetArrayToObject } from "../lib/graphql-helpers";
import { useCheckScreenSize } from "../lib/checkScreenSize";
import {
  Insurance,
  Testimonials,
  Service,
  Partners,
} from "../components/homepage";
import "../styles/pages/homepage.scss";
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

export default function Home({ data }) {
  const heroImageRef = useRef(null);
  const protectImageRef = useRef(null);
  const venturaWaveRef = useRef(null);
  const subheaderRef = useRef(null);
  const honoluluRef = useRef(null);
  const rinsurePageRef = useRef(null);
  const annotationRef = useRef(null);
  const trustedByHeaderRef = useRef(null);
  const businessesHeaderRef = useRef(null);
  const experienceHeaderRef = useRef(null);
  const contactHeaderRef = useRef(null);
  const savingsHeaderRef = useRef(null);
  const animationsRef = useRef([]);
  const {
    insurance: { edges: insuranceImages },
    testimonials: { edges: testimonials },
    partners: { edges: partners },
  } = data;
  const isMobile = useCheckScreenSize(640);

  const sortedInsuranceImages = assetArrayToObject({
    useGatsbyImage: true,
    images: insuranceImages,
  });

  const sortedPartnerImages = assetArrayToObject({
    useGatsbyImage: true,
    images: partners,
  });

  useEffect(() => {
    // Helper function to create an animation and store it in animationsRef
    const createAnimation = (animation) => {
      animationsRef.current.push(animation);
    };

    if (rinsurePageRef.current) {
      const leftTextElements =
        rinsurePageRef.current.querySelectorAll(".ri-anim-left");
      const rightTextElements =
        rinsurePageRef.current.querySelectorAll(".ri-anim-right");
      const bottomTextElements =
        rinsurePageRef.current.querySelectorAll(".ri-anim-bottom");

      leftTextElements.forEach((ele) => {
        createAnimation(
          gsap.fromTo(
            ele,
            { x: -150, opacity: 0 },
            {
              x: 0,
              opacity: 1,
              duration: 1,
              ease: "power1.out",
              scrollTrigger: {
                trigger: ele,
                start: "top bottom",
                end: "bottom top",
              },
            }
          )
        );
      });

      rightTextElements.forEach((ele) => {
        createAnimation(
          gsap.fromTo(
            ele,
            { x: 150, opacity: 0 },
            {
              x: 0,
              opacity: 1,
              duration: 0.75,
              ease: "power1.out",
              scrollTrigger: {
                trigger: ele,
                start: "top bottom",
                end: "bottom top",
              },
            }
          )
        );
      });

      bottomTextElements.forEach((ele) => {
        createAnimation(
          gsap.fromTo(
            ele,
            { y: 150, opacity: 0 },
            {
              y: 0,
              opacity: 1,
              duration: 0.75,
              ease: "power1.out",
              scrollTrigger: {
                trigger: ele,
                start: "top bottom",
                end: "bottom top",
              },
            }
          )
        );
      });
    }

    const parallaxElements = [
      { ref: heroImageRef, triggerClass: "hero-image-class", startY: -400 },
      { ref: venturaWaveRef, triggerClass: "ventura-class", startY: -100 },
      { ref: honoluluRef, triggerClass: "honolulu-class", startY: -131 },
      {
        ref: protectImageRef,
        triggerClass: "protect-image-class",
        startY: -400,
      },
    ];

    parallaxElements.forEach(({ ref, triggerClass, startY }) => {
      if (ref.current) {
        createAnimation(
          gsap.fromTo(
            ref.current,
            { y: startY },
            {
              y: 0,
              scrollTrigger: {
                trigger: triggerClass ? `.${triggerClass}` : ref.current,
                scrub: true,
                start: "top bottom",
                end: "bottom top",
              },
            }
          )
        );
      }
    });

    if (annotationRef.current) {
      const timeline = gsap.timeline({
        defaults: { duration: 1, ease: "power1.inOut" },
        scrollTrigger: {
          trigger: annotationRef.current,
          start: "top center",
          end: "bottom center",
        },
      });

      const paths = annotationRef.current.querySelectorAll("path");

      paths.forEach((path) => {
        timeline.fromTo(path, { drawSVG: "0%" }, { drawSVG: "100%" });
      });

      // Store timeline animation
      animationsRef.current.push(timeline);
    }

    const subheaderRefs = [
      subheaderRef,
      trustedByHeaderRef,
      businessesHeaderRef,
      experienceHeaderRef,
      contactHeaderRef,
      savingsHeaderRef,
    ];

    subheaderRefs.forEach((headerRef) => {
      if (headerRef.current) {
        const subHeaderWords = [...headerRef.current.children];

        // Set the initial position before animation starts
        subHeaderWords.forEach((word) => {
          gsap.set(word.children[0], { y: 40 });
        });

        // Apply the fromTo animation with ScrollTrigger
        subHeaderWords.forEach((word, index) => {
          createAnimation(
            gsap.to(word.children[0], {
              y: 0,
              duration: 1,
              delay: index * 0.03,
              scrollTrigger: {
                trigger: headerRef.current,
                start: "top bottom",
                end: "bottom top",
                toggleActions: "play none none reset",
              },
            })
          );
        });
      }
    });

    return () => {
      // Properly kill all animations and their scroll triggers
      animationsRef.current.forEach((anim) => {
        anim.scrollTrigger?.kill(); // Kill the associated scroll trigger
        anim.kill(); // Kill the GSAP animation itself
      });
      animationsRef.current = []; // Clear the reference array
    };
  }, []);

  return (
    <div
      ref={rinsurePageRef}
      className="flex flex-col items-center w-full bg-white"
    >
      <section className="relative flex w-full h-[110vh] overflow-hidden items-start justify-start">
        <div
          ref={heroImageRef}
          className="flex flex-col items-start justify-start w-full brightness-70 hero-image-class rounded-custom overflow-hidden"
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            loading="eager"
            className="object-cover w-full h-[105vh] xl:h-full"
            src="https://s3.us-west-1.amazonaws.com/assets.rinsure.com/rinsure-reel.mp4"
          />
        </div>
        <div className="absolute flex flex-col inset-0 flex items-start justify-center z-30 gap-10 px-5">
          <div className="flex flex-col items-start justify-start">
            <h1 className="xl:text-8xl lg:text-6xl md:text-5xl sm:text-4xl text-3xl font- mix-blend-difference text-white text-left max-w-[800px] font-normal mb-2">
              Protect Your{" "}
              <RotatingText
                duration={4}
                texts={["Home", "Business", "Car", "Assets", "Health"]}
              />{" "}
            </h1>
            <h1 className="xl:text-8xl lg:text-6xl md:text-5xl sm:text-4xl text-3xl font- mix-blend-difference text-white text-left max-w-[800px] font-normal mb-4">
              with Confidence.
            </h1>
            <h3 className="sm:text-2xl text-xl font- mix-blend-difference text-white text-left max-w-screen-lg font-semibold mb-10">
              Instant. Simple. Smart.
            </h3>
            <div className="flex sm:flex-row flex-col items-start justify-start gap-8 w-full sm:pt-0 pt-10">
              <div>
                <Button theme="large" to="/about">
                  Learn more
                </Button>
              </div>
              <div>
                <Button theme="hollow-large" to="/">
                  Get in touch
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full flex flex-col items-center justify-center subheader-class relative">
        <div className="w-full bg-white flex flex-col xl:flex-row items-start justify-between max-w-screen-2xl py-16 px-8 box-border gap-12 relative">
          <h5 className="text-darkGrey font-bold">Company</h5>
          <div className="flex flex-col gap-12 items-center justify-center">
            <div className="flex flex-col items-start justify-center gap-8">
              <h2
                ref={subheaderRef}
                className="flex flex-wrap gap-1 box-border text-black xl:text-6xl lg:text-4xl md:text-2xl sm:text-xl text-2xl xl:max-w-[600px] transition-all"
              >
                {"Welcome to Rothstein Insurance, where we value your business as much as our own."
                  .split(" ")
                  .map((word, index) => (
                    <span
                      key={`${word}-${index}`}
                      className="inline-flex box-border overflow-hidden relative mr-2 leading-[1.15]"
                    >
                      <span className="block relative">{`${word} `}</span>
                    </span>
                  ))}
              </h2>
            </div>
            <div className="w-full xl:max-w-xl rounded-[12px] h-[150px] sm:h-[250px] md:h-[300px] lg:h-[400px] xl:h-[284px] overflow-hidden ri-anim-left">
              <div ref={venturaWaveRef} className="w-full xl:max-w-4xl">
                <StaticImage
                  src="../images/homepage/ventura-beach.jpg"
                  alt="insurance-illustration"
                  quality={70}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  layout="fullWidth"
                  placeholder="blurred"
                  loading="lazy"
                  className="rounded-[12px]"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col-reverse xl:flex-col items-start justify-center gap-8 w-full xl:max-w-xl ventura-class">
            <div className="w-full xl:max-w-xl rounded-[12px] overflow-hidden h-[200px] sm:h-[280px] md:h-[350px] lg:h-[450px] xl:h-[300px] honolulu-class ri-anim-right relative">
              <div ref={honoluluRef} className="w-full xl:max-w-4xl">
                <StaticImage
                  src="../images/homepage/honolulu-tall.jpg"
                  alt="insurance-illustration"
                  quality={70}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  layout="fullWidth"
                  placeholder="blurred"
                  loading="lazy"
                  className="rounded-[12px]"
                />
              </div>
            </div>
            <div className="flex flex-col gap-4 relative max-w-[576px]">
              <h3 className="text-black text-2xl font-semibold ri-anim-right z-20 w-full relative">
                Based in Ventura, CA and Honolulu, HI, we are a company that
                specializes in industry-specific insurance solutions.
              </h3>
              <p className="text-black text-lg w-full font-extralight ri-anim-right z-20">
                Running a business involves significant risk, but a tailored
                insurance policy from Rothstein Insurance Services Inc can
                provide the security you need. By choosing us, you protect your
                business, your employees, and yourself. We offer a comprehensive
                overview of coverage options and work collaboratively to craft
                intelligent, straightforward insurance solutions that minimize
                risk costs and enhance your competitive advantage. Join the
                hundreds of businesses and individuals across the country who
                trust us to secure their operations.
              </p>
              {!isMobile && (
                <svg
                  width="619"
                  height="89"
                  viewBox="0 0 619 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute top-[7%] right-0 z-10"
                  ref={annotationRef}
                >
                  <path
                    d="M45 3.49994C165.5 6.99994 483.5 -0.5 614.5 3.49994"
                    stroke="#89BEDD"
                    strokeWidth="4"
                  />
                  <path
                    d="M46 33.8052C248.5 33.8053 422.5 28 619 33.8052"
                    stroke="#89BEDD"
                    strokeWidth="4"
                  />
                  <path
                    d="M1 79C790.655 54.3172 -170.632 -16.6401 67.0994 86.997"
                    stroke="#89BEDD"
                    strokeWidth="4"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="w-full rounded-b-xl">
        <Insurance images={sortedInsuranceImages} />
      </section>
      <section className="w-full bg-white flex flex-col items-center justify-center max-w-screen-2xl protect-image-class rounded-md px-8 sm:py-16 bg-grey">
        <div className="flex items-start justify-start box-border w-full overflow-hidden rounded-[24px] h-[90vh] relative">
          <div
            ref={protectImageRef}
            className="w-full flex items-center justify-center box-border rounded-[24px] overflow-hidden"
          >
            <StaticImage
              className="rounded-md w-full h-[110vh] xl:h-full brightness-80"
              src="../images/homepage/family-beach-car-2.jpg"
              alt="start-protecting"
              quality={70}
              formats={["AUTO", "WEBP", "AVIF"]}
              layout="fullWidth"
              placeholder="blurred"
              // width={1816}
              // height={1507}
              loading="lazy"
            />
          </div>
          <div className="flex flex-col md:flex-row justify-between absolute bottom-0 right-0 w-full p-8 items-start md:items-end">
            <h2 className="text-white xl:text-6xl lg:text-4xl md:text-2xl sm:text-xl text-2xl max-w-[600px] mb-4 md:mb-0">
              Start protecting what matters most.
            </h2>
            <div className="ri-insurance__index__button__wrapper">
              <Button theme="large" to="/contact">
                Get a quote
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full bg-white flex flex-col items-center justify-center max-w-screen-2xl protect-image-class rounded-md px-8 py-16 bg-grey">
        <div className="w-full bg-white flex flex-col items-center justify-center max-w-screen-2xl sm:py-16 px-8 box-border gap-16 px-8">
          <div className="grid grid-rows-2 sm:grid-cols-4 sm:grid-rows-1 w-full">
            <h5 className="text-darkGrey font-bold">Experience</h5>
            <div className="flex flex-col items-center justify-center gap-4 col-span-2">
              <h2
                ref={experienceHeaderRef}
                className="flex flex-wrap gap-1 box-border text-black xl:text-6xl lg:text-4xl md:text-2xl sm:text-xl text-2xl max-w-[800px] transition-all items-center justify-center"
              >
                {"What you will get.".split(" ").map((word, index) => (
                  <span
                    key={`${word}-${index}`}
                    className="inline-flex box-border overflow-hidden relative mr-2 leading-[1.15]"
                  >
                    <span className="block relative">{`${word} `}</span>
                  </span>
                ))}
              </h2>
              <p className="text-blue font-bold">
                Discover the Rothstein Insurance difference.
              </p>
            </div>
          </div>
          <Service />
        </div>
      </section>
      <section className="w-full flex flex-col items-center justify-center max-w-screen-2xl protect-image-class rounded-md bg-grey">
        <div className="w-full flex flex-col items-center justify-center max-w-screen-2xl py-8 sm:py-16 md:py-32 px-8 box-border gap-16">
          <div className="grid grid-rows-2 md:grid-cols-4 md:grid-rows-1 w-full">
            <h5 className="text-blue font-bold">Contact</h5>
            <div className="flex flex-col col-span-2 items-center">
              <h2
                ref={contactHeaderRef}
                className="flex flex-wrap gap-1 box-border text-black xl:text-6xl lg:text-4xl md:text-3xl text-2xl max-w-[700px] transition-all text-blue items-center justify-center"
              >
                {"Not sure what you need? Speak with an agent."
                  .split(" ")
                  .map((word, index) => (
                    <span
                      key={`${word}-${index}`}
                      className="inline-flex box-border overflow-hidden relative mr-2 leading-[1.15]"
                    >
                      <span className="block relative">{`${word} `}</span>
                    </span>
                  ))}
              </h2>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-center justify-center gap-12">
            <div className="flex flex-col max-w-[90vh] w-full lg:w-[50%] justify-between gap-4 bg-blue px-10 py-12 rounded-lg overflow-hidden group h-[240px] sm:h-[340px] cursor-pointer ri-anim-left hover:shadow-xl transition-all duration-450 ease-in-out shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="180"
                height="180"
                viewBox="0 0 24 24"
                className="self-end"
              >
                <g fill="none">
                  <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                  <path
                    fill="var(--white)"
                    d="M6.857 2.445C8 3.278 8.89 4.415 9.65 5.503l.442.644l.417.615a1.504 1.504 0 0 1-.256 1.986l-1.951 1.449a.48.48 0 0 0-.142.616c.442.803 1.228 1.999 2.128 2.899s2.153 1.738 3.012 2.23a.483.483 0 0 0 .644-.162l1.27-1.933a1.503 1.503 0 0 1 2.056-.332l.663.459c1.239.86 2.57 1.837 3.588 3.14a1.47 1.47 0 0 1 .189 1.484c-.837 1.953-2.955 3.616-5.158 3.535l-.3-.017l-.233-.02l-.258-.03l-.281-.038l-.305-.05l-.16-.03l-.336-.072l-.176-.04l-.366-.094l-.385-.11l-.402-.13c-1.846-.626-4.189-1.856-6.593-4.26s-3.633-4.746-4.259-6.592l-.13-.402l-.11-.385l-.094-.366l-.078-.346a12 12 0 0 1-.063-.326l-.05-.305l-.04-.281l-.029-.258l-.02-.233l-.016-.3c-.081-2.196 1.6-4.329 3.544-5.162a1.47 1.47 0 0 1 1.445.159m8.135 3.595l.116.013a3.5 3.5 0 0 1 2.858 2.96a1 1 0 0 1-1.958.393l-.023-.115a1.5 1.5 0 0 0-1.07-1.233l-.155-.035a1 1 0 0 1 .232-1.983M15 3a6 6 0 0 1 6 6a1 1 0 0 1-1.993.117L19 9a4 4 0 0 0-3.738-3.991L15 5a1 1 0 1 1 0-2"
                  />
                </g>
              </svg>
              <h3 className="text-3xl sm:text-5xl xl:text-6xl pt-[30px] h-full opacity-100 text-white group-hover:opacity-0 group-hover:h-0 transition-all duration-450 h-0 ease-out overflow-hidden group-hover:-translate-y-full">
                Give us a call
              </h3>
              <h3 className="text-3xl sm:text-5xl xl:text-6xl overflow-hidden transition-all duration-450 h-0 ease-out text-white opacity-0 group-hover:opacity-100 group-hover:h-full">
                (805) 648-6595
              </h3>
            </div>
            <Link to="/contact" className="max-w-[90vh] w-full lg:w-[50%]">
              <div className="flex flex-col justify-between gap-4 bg-blue px-10 py-12 rounded-lg overflow-hidden group h-[240px] sm:h-[340px] cursor-pointer ri-anim-right hover:shadow-xl transition-all duration-450 ease-in-out shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="180"
                  height="180"
                  viewBox="0 0 24 24"
                  className="self-end"
                >
                  <path
                    fill="var(--white)"
                    d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 4l-8 5l-8-5V6l8 5l8-5z"
                  />
                </svg>
                <h3 className="text-3xl sm:text-5xl xl:text-6xl pt-[30px] h-full opacity-100 text-white group-hover:opacity-0 group-hover:h-0 transition-all duration-450 h-0 ease-out overflow-hidden group-hover:-translate-y-full">
                  Send a message
                </h3>
                <h3 className="text-3xl sm:text-5xl xl:text-6xl overflow-hidden transition-all duration-450 h-0 ease-out text-white opacity-0 group-hover:opacity-100 group-hover:h-full">
                  csr@rinsure.com
                </h3>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <section className="w-full bg-white flex flex-col items-center justify-center md:py-[80px]">
        <div className="w-full bg-white flex flex-col items-center justify-center max-w-screen-2xl py-8 sm:py-16 box-border gap-8 px-8">
          <div className="flex flex-col self-start">
            <h5 className="self-start text-darkGrey font-bold mb-4">
              Testimonials
            </h5>
            <h2
              ref={businessesHeaderRef}
              className="flex flex-wrap gap-1 box-border text-black xl:text-6xl lg:text-4xl md:text-2xl sm:text-xl text-2xl max-w-[700px] transition-all"
            >
              {"Businesses are talking.".split(" ").map((word, index) => (
                <span
                  key={`${word}-${index}`}
                  className="inline-flex box-border overflow-hidden relative mr-2 leading-[1.15]"
                >
                  <span className="block relative">{`${word} `}</span>
                </span>
              ))}
            </h2>
          </div>
          <Testimonials testimonials={testimonials} />
        </div>
      </section>
      <section className="w-full flex flex-col items-center justify-center max-w-screen-2xl protect-image-class rounded-md bg-white">
        <div className="w-full bg-white flex flex-col items-center justify-center max-w-screen-2xl py-8 md:py-16 px-8 box-border gap-0 px-8 gap-12">
          <div className="grid grid-rows-2 md:grid-cols-4 md:grid-rows-1 w-full">
            <h5 className="text-darkGrey font-bold">Partners</h5>
            <h2
              ref={trustedByHeaderRef}
              className="flex flex-wrap gap-1 box-border text-black xl:text-6xl lg:text-4xl md:text-2xl sm:text-xl text-2xl max-w-[700px] transition-all items-center justify-center col-span-2"
            >
              {"Partnered with and trusted by top leaders in the industry."
                .split(" ")
                .map((word, index) => (
                  <span
                    key={`${word}-${index}`}
                    className="inline-flex box-border overflow-hidden relative mr-2 leading-[1.15]"
                  >
                    <span className="block relative">{`${word} `}</span>
                  </span>
                ))}
            </h2>
          </div>
          <Partners images={sortedPartnerImages} />
        </div>
      </section>
      <section className="w-full flex flex-col items-center justify-center max-w-screen-2xl protect-image-class rounded-md bg-white">
        <div className="w-full bg-white box-border flex flex-col items-center justify-center max-w-screen-2xl py-8 md:py-16 px-8 box-border gap-8 px-8">
          <div className="w-full flex-col sm:px-8 sm:py-8 md:py-16 rounded-[48px] flex items-center justify-center gap-12">
            <h1
              ref={savingsHeaderRef}
              className="flex flex-wrap gap-1 box-border text-black xl:text-8xl lg:text-6xl md:text-2xl sm:text-xl text-4xl transition-all items-center justify-center col-span-2"
            >
              {"Ready to start saving?".split(" ").map((word, index) => (
                <span
                  key={`${word}-${index}`}
                  className="inline-flex box-border overflow-hidden relative mr-2 leading-[1.15]"
                >
                  <span className="block relative">{`${word} `}</span>
                </span>
              ))}
            </h1>
            <div className="w-full max-w-[300px]">
              <Button theme="hollow-large-black" to="/contact">
                Get in touch.
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export const query = graphql`
  query {
    insurance: allFile(
      filter: {
        extension: { eq: "jpg" }
        relativeDirectory: { eq: "insurance" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(width: 420, placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
    partners: allFile(
      filter: {
        extension: { eq: "png" }
        relativeDirectory: { eq: "partners" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(width: 420, placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
    testimonials: allContentfulTestimonial(
      filter: { isLive: { eq: true } }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          quote {
            quote
          }
          isLive
          id
          fullName
          company
          role
        }
      }
    }
  }
`;
