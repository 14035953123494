import React from "react";
import { partners } from "../../../data/pages/homepage";
import { GatsbyImage } from "gatsby-plugin-image";

const Partners = ({ images }) => {
  return (
    <div className="grid grid-rows-4 sm:grid-cols-4 sm:grid-rows-1 gap-8 w-full">
      {partners.map((partner) => {
        const { image, animation } = partner;
        return (
          <div
            className={`${animation} flex items-center justify-center content-center bg-grey rounded-lg px-4 py-8 sm:max-w-[400px] group shadow-custom-light`}
            key={image}
          >
            <GatsbyImage
              className="group-hover:scale-105 brightness-95 group-hover:brightness-100 transition-all ease-in-out duration-300"
              image={images[image]}
              alt={image}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Partners;
