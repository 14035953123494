import React, { useEffect, useRef } from "react";
import "./testimonials.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import TestimonialCard from "./testimonial-card.js";
gsap.registerPlugin(ScrollTrigger);

const Testimonials = ({ testimonials }) => {
  const containerRef = useRef(null);
  const indexRef = useRef(null);
  const animationsRef = useRef([]); // Store GSAP animation instances for cleanup

  useEffect(() => {
    // Cleanup previous animations if they exist
    animationsRef.current.forEach((anim) => {
      anim.scrollTrigger?.kill(); // Kill associated ScrollTrigger
      anim.kill(); // Kill the animation itself
    });
    animationsRef.current = []; // Clear previous animations

    if (indexRef.current) {
      const indexRefChildren = [...indexRef.current.children];

      indexRefChildren.forEach((child, index) => {
        // Create GSAP animation and store it for later cleanup
        const anim = gsap.fromTo(
          child,
          {
            y: 100,
            opacity: 0.1,
          },
          {
            y: 0,
            opacity: 1,
            duration: 0.5,
            delay: index * 0.1, // stagger delay between children
            scrollTrigger: {
              trigger: containerRef.current, // Use the containerRef as trigger
              start: "top bottom", // Start when the top of container hits the bottom of the viewport
              end: "bottom top", // End when bottom of container hits the top of the viewport
              toggleActions: "play none none reset", // Play the animation and reset when scrolling back above
            },
          }
        );

        animationsRef.current.push(anim); // Store animation in the ref
      });
    }

    // Cleanup function to kill animations and scroll triggers
    return () => {
      animationsRef.current.forEach((anim) => {
        anim.scrollTrigger?.kill(); // Properly kill the associated ScrollTrigger
        anim.kill(); // Kill the GSAP animation itself
      });
      animationsRef.current = []; // Clear the reference array
    };
  }, [testimonials]); // Ensure useEffect runs when testimonials change

  return (
    <div ref={containerRef} className="testimonials relative">
      <div ref={indexRef} className="testimonials__index">
        {testimonials.map((testimonial, index) => {
          return (
            <TestimonialCard
              testimonial={testimonial}
              key={testimonial.node.id}
            />
          );
        })}
      </div>
      <div className="z-20 bg-gradient-to-b from-transparent to-white w-full h-[1000px] absolute inset-x-0 bottom-0"></div>
    </div>
  );
};

export default Testimonials;
