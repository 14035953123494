import React, { useRef, useEffect } from "react";
import { insurance } from "../../../data/pages/homepage";
import { GatsbyImage } from "gatsby-plugin-image";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/all";
import { useCheckScreenSize } from "../../../lib/checkScreenSize";
import "./insurance.scss";
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

const Insurance = ({ images }) => {
  const indexRef = useRef(null);
  const drawCircleRef = useRef(null);
  const animationsRef = useRef([]); // Store animation instances for cleanup
  const isTablet = useCheckScreenSize(911);
  const isMobile = useCheckScreenSize(640);

  useEffect(() => {
    // Helper function to store animations for later cleanup
    const createAnimation = (animation) => {
      animationsRef.current.push(animation);
    };

    if (indexRef.current) {
      const indexChildren = [...indexRef.current.children];

      indexChildren.forEach((card) => {
        const image = card.querySelector(".ri-insurance__card__image");
        const realImage = card.querySelector(".gatsby-image-wrapper");
        const text = card.querySelector(".ri-insurance__card__text");

        // Animation for realImage moving vertically
        createAnimation(
          gsap.fromTo(
            realImage,
            { y: -80 },
            {
              y: 0,
              scrollTrigger: {
                scrub: true,
                trigger: card,
                start: "top bottom",
                end: "bottom top",
              },
            }
          )
        );

        // Animation for clipping and opacity of image
        createAnimation(
          gsap.fromTo(
            image,
            {
              clipPath: "inset(20%)",
              "--aspect": "150%",
              visibility: "hidden",
              opacity: 0,
              ease: "power1.out",
            },
            {
              clipPath: "inset(0%)",
              "--aspect": "100%",
              visibility: "inherit",
              opacity: 1,
              duration: 0.5,
              scrollTrigger: {
                trigger: card,
                start: "top center",
                end: "bottom top",
              },
            }
          )
        );

        // Animation for scaling the realImage
        createAnimation(
          gsap.fromTo(
            realImage,
            {
              width: 700,
              scale: 1.25,
              duration: 0.75,
              ease: "power1.out",
            },
            {
              width: 420,
              scale: 1,
              duration: 0.75,
              scrollTrigger: {
                trigger: card,
                start: "top center",
                end: "bottom top",
              },
            }
          )
        );

        // Animation for text visibility and opacity
        createAnimation(
          gsap.fromTo(
            text,
            {
              opacity: 0,
              visibility: "hidden",
              duration: 0.5,
              ease: "power1.out",
            },
            {
              opacity: 1,
              visibility: "inherit",
              duration: 0.5,
              scrollTrigger: {
                trigger: card,
                start: "top center",
                end: "bottom bottom",
              },
            }
          )
        );
      });
    }

    if (drawCircleRef.current) {
      const timeline = gsap.timeline({
        defaults: { duration: 1, ease: "power1.inOut" },
        scrollTrigger: {
          trigger: drawCircleRef.current,
          start: "top center",
          end: "bottom center",
        },
      });

      const paths = drawCircleRef.current.querySelectorAll("path");

      paths.forEach((path) => {
        timeline.fromTo(path, { drawSVG: "0%" }, { drawSVG: "100%" });
      });

      // Store timeline animation
      animationsRef.current.push(timeline);
    }

    if (indexRef.current) {
      const paths = indexRef.current.querySelectorAll("path");

      paths.forEach((path) => {
        createAnimation(
          gsap.fromTo(
            path,
            { drawSVG: "0%" },
            {
              drawSVG: "100%",
              scrollTrigger: {
                trigger: path,
                start: "top center",
                end: "bottom center",
              },
            }
          )
        );
      });
    }

    // Cleanup function to kill animations and scroll triggers
    return () => {
      animationsRef.current.forEach((anim) => {
        anim.scrollTrigger?.kill(); // Properly kill the associated ScrollTrigger
        anim.kill(); // Kill the GSAP animation itself
      });
      animationsRef.current = []; // Clear the reference array
    };
  }, []);
  return (
    <section id="ri-about-insurance" className="ri-insurance">
      <div className="ri-insurance__inner">
        <div className="ri-insurance__header ri-anim-left">
          <h5 className="text-darkGrey">Services</h5>
          <div className="relative w-full h-full">
            <h2 className="z-20">Better Insurance for Everyone.</h2>
            {!isMobile && (
              <svg
                width="403"
                height="109"
                viewBox="0 0 403 109"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-[39%] right-[11%] z-10"
                ref={drawCircleRef}
              >
                <path
                  d="M1 91.6491C995.52 44.2681 -215.159 -91.941 84.248 107"
                  stroke="#89BEDD"
                  strokeWidth="4"
                />
              </svg>
            )}
          </div>
          <p>
            RISINC makes it easy to insure your business and personal insurance.
          </p>
        </div>
        <div ref={indexRef} className="ri-insurance__index">
          {insurance.map((card) => {
            const { image, text, header } = card;
            return (
              <div key={image} className="ri-insurance__card">
                <div className="ri-insurance__card__image">
                  <div className="ri-insurance__card__image__wrapper">
                    <GatsbyImage alt={image} image={images[image]} />
                  </div>
                </div>

                <div className="ri-insurance__card__text relative">
                  <h2>{header}</h2>
                  <p>{text}</p>
                  {!isTablet && (
                    <svg
                      width="58"
                      height="68"
                      viewBox="0 0 58 68"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute top-[-10%] right-[-6%]"
                    >
                      <path
                        d="M2 51.2536C4.08294 54.1697 11.2148 67.9209 15.6816 64.6864C23.5153 59.0137 28.5101 48.2716 33.4676 40.3084C41.2905 27.7425 48.1263 14.6748 55.7312 2"
                        stroke="#89BEDD"
                        strokeWidth="4"
                        strokeLinecap="round"
                      />
                    </svg>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Insurance;
