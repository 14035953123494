import React from "react";

const Service = () => {
  return (
    <div className="grid grid-rows-3 md:grid-cols-3 md:grid-rows-1 w-full gap-6 lg:gap-12">
      <div className="flex flex-col justify-between gap-4 bg-grey px-5 lg:px-10 py-6 lg:py-12 rounded-lg overflow-hidden group h-[340px] ri-anim-left hover:shadow-xl transition-all duration-450 ease-out shadow-lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="90"
          height="90"
          viewBox="0 0 24 24"
          className="self-end"
        >
          <path
            fill="var(--blue)"
            d="M14.121 10.48a1 1 0 0 0-1.414 0l-.707.706a2 2 0 0 1-2.828-2.828l5.63-5.632a6.5 6.5 0 0 1 6.377 10.568l-2.108 2.135zM3.161 4.468a6.5 6.5 0 0 1 8.009-.938L7.757 6.944a4 4 0 0 0 5.513 5.794l.144-.137l4.243 4.242l-4.243 4.243a2 2 0 0 1-2.828 0L3.16 13.66a6.5 6.5 0 0 1 0-9.192"
          />
        </svg>
        <h3 className="text-xl sm:text-4xl md:text-xl lg:text-2xl pt-[30px] font-semibold">
          Exceptional Service
        </h3>
        <p className="text-sm overflow-hidden group-hover:h-full transition-all duration-450 h-0 ease-out">
          Rothstein Insurance Services Inc consistently delivers value, a range
          of options and personalized service to our clients in Independence.
        </p>
      </div>
      <div className="flex flex-col justify-between gap-4 bg-grey px-5 lg:px-10 py-6 lg:py-12 rounded-lg overflow-hidden group h-[340px] ri-anim-bottom hover:shadow-xl transition-all duration-450 ease-out shadow-lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="90"
          height="90"
          viewBox="0 0 24 24"
          className="self-end"
        >
          <path
            fill="var(--blue)"
            d="M19 9a7 7 0 1 0-10.974 5.76L5 20l2.256.093L8.464 22l3.466-6.004L12 16c.035.002.046-.003.07-.004L15.536 22l1.232-1.866L19 20l-3.026-5.24A6.99 6.99 0 0 0 19 9M7 9a5 5 0 1 1 5 5a5 5 0 0 1-5-5"
          />
          <circle cx="12" cy="9" r="3" fill="var(--blue)" />
        </svg>
        <h3 className="text-xl sm:text-4xl md:text-xl lg:text-2xl pt-[30px] font-semibold">
          Trustworthy & Knowledgeable Advisors
        </h3>
        <p className="text-sm overflow-hidden group-hover:h-full transition-all duration-450 h-0 ease-out">
          Your personal consultant is committed to staying informed on a range
          of options at the most affordable rates.
        </p>
      </div>
      <div className="flex flex-col justify-between gap-4 bg-grey px-5 lg:px-10 py-6 lg:py-12 rounded-lg overflow-hidden group h-[340px] ri-anim-right hover:shadow-xl transition-all duration-450 ease-out shadow-lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="90"
          height="90"
          viewBox="0 0 24 24"
          className="self-end"
        >
          <path
            fill="var(--blue)"
            d="m11.005 2l7.298 2.28a1 1 0 0 1 .702.955V7h2a1 1 0 0 1 1 1v2h-13V8a1 1 0 0 1 1-1h7V5.97l-6-1.876l-6 1.876v7.404a4 4 0 0 0 1.558 3.169l.189.136l4.253 2.9L14.787 17h-4.782a1 1 0 0 1-1-1v-4h13v4a1 1 0 0 1-1 1l-3.22.001c-.387.51-.857.96-1.4 1.33L11.005 22l-5.38-3.668a6 6 0 0 1-2.62-4.958V5.235a1 1 0 0 1 .702-.954z"
          />
        </svg>
        <h3 className="text-xl sm:text-4xl md:text-xl lg:text-2xl pt-[30px] font-semibold">
          Convenient Claims and Payment Methods
        </h3>
        <p className="text-sm overflow-hidden group-hover:h-full transition-all duration-450 h-0 ease-out">
          Automatic payment and withdrawal, simplified reporting for claims.
        </p>
      </div>
    </div>
  );
};

export default Service;
